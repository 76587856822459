import React from "react";

const About = ({ about }) => {
  return (
    <div className="lg:w-[538px] bg-white p-12 flex flex-col gap-4">
      <h1
        className="text-lg uppercase"
        style={{
          fontFamily: "ClashGrotesk-bold, sans-serif",
          letterSpacing: "1px",
        }}
      >
        About{" "}
      </h1>

      {about.map((about, index) => {
        return (
          <p
            key={index}
            className="text-[17px]"
            style={{
              color: "#212536",
              textAlign: "left",
              fontFamily: "ClashGrotesk-regular, sans-serif",
              lineHeight: "26.4px",
            }}
          >
            {about}
          </p>
        );
      })}
      {/* <p
        className="text-[17px]"
        style={{
          color: "#212536",
          textAlign: "left",
          fontFamily: "ClashGrotesk-regular, sans-serif",
          lineHeight: "26.4px",
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In auctor
        ullamcorper mi, nec egestas mi hendrerit quis. Cras tristique turpis sit
        amet mi blandit, sed finibus urna consequat. Aliquam nec sapien mattis,
        blandit augue at, semper neque. Integer egestas est quis est venenatis,
        ac tristique lectus lacinia. Praesent id leo nec risus vulputate
        ultrices.
      </p>
      <p
        className="text-[17px]"
        style={{
          color: "#212536",
          textAlign: "left",
          fontFamily: "ClashGrotesk-regular, sans-serif",
          lineHeight: "26.4px",
        }}
      >
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero autem
        voluptatum dignissimos ipsum. Sunt consequatur at ut laborum molestias
        ad?
      </p> */}
    </div>
  );
};

export default About;
