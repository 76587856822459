import React, { useEffect, useState } from "react";

const About = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  }, []);
  return (
    <div className='flex flex-col gap-8 px-4 lg:px-32'>
      <h1
        className='mt-20'
        style={{
          color: '#FFF',
          textAlign: 'center',
          fontFamily: 'ClashGrotesk-bold, sans-serif',
          fontSize: '45px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '50px',
          letterSpacing: '2.15px',
          textTransform: 'uppercase',
        }}
      >
        OUR <span className='text-[#45FF79]'>STREAM</span>
      </h1>
      {screenWidth >= 768 ? (
        <p
          className='text-[#CDD4EE] text-justify lg:text-center text-[17px] font-normal leading-[26.4px] opacity-[0.7] lg:px-[285px]'
          style={{ fontFamily: 'ClashGrotesk-extralight, sans-serif' }}
        >
          LuckyRollTV is a casino streaming channel that is dedicated to
          providing an entertaining and engaging experience for the community.
          LuckyRollTV strives to create the best and most creative content for
          its viewers. Through contest, competitions and giveaways we try to
          always give back to our loyal community members. The team at
          LuckyRollTV is highly dedicated to creating a positive and welcoming
          environment for our viewers. We strive to ensure that our viewers feel
          safe and respected while participating in their streams. The team is
          always looking for new ways to engage with their viewers and to
          provide quality content. We are passionate about streaming and strive
          to create the best streaming experience possible. If you’re looking
          for an entertaining and friendly casino streaming community, check us
          out!
        </p>
      ) : (
        <p
          className='text-[#CDD4EE] text-justify lg:text-center text-[17px] font-normal leading-[26.4px] opacity-[0.7] lg:px-[285px]'
          style={{ fontFamily: 'ClashGrotesk-extralight, sans-serif' }}
        >
          LuckyRollTV is a casino streaming channel that is dedicated to
          providing an entertaining and engaging experience for the community.
          LuckyRollTV strives to create the best and most creative content for
          its viewers. Through contest, competitions and giveaways we try to
          always give back to our loyal community members. <br /> <br /> The
          team at LuckyRollTV is highly dedicated to creating a positive and
          welcoming environment for our viewers. We strive to ensure that our
          viewers feel safe and respected while participating in their streams.
          The team is always looking for new ways to engage with their viewers
          and to provide quality content. We are passionate about streaming and
          strive to create the best streaming experience possible. If you’re
          looking for an entertaining and friendly casino streaming community,
          check us out!
        </p>
      )}
    </div>
  );
};

export default About;
