import background from "../../../assets/banner/bg_playtable.png";
import characterMen from "../../../assets//banner/character_men.png";
import characterWomen from "../../../assets//banner/character_women.png";
import characterDecor from "../../../assets//banner/character-decor.png";

import btnIcon from "../../../assets//banner/btn_icon.png";
import fj_logo from "../../../assets/banner/fj_logo.png";
import Button from "../../Button";

import wazamba from "../../../assets//banner/cards/wazamba/logo.png";
import bgWazamba from "../../../assets//banner/cards/wazamba/bg.png";

import slotamba from "../../../assets//banner/cards/slotamba/logo.png";
import bgSlotamba from "../../../assets//banner/cards/slotamba/bg.png";

import heycasino from "../../../assets//banner/cards/heycasino/logo.png";
import bgheycasino from "../../../assets//banner/cards/heycasino/bg.png";

import arcanebet from "../../../assets//banner/cards/arcanebet/logo.png";
import bgarcanebet from "../../../assets//banner/cards/arcanebet/bg.png";

import Card from "./Card";
import InfoIcon from "../../InfoIcon";

const cardInfo = [
  {
    bg: bgWazamba,
    logo: wazamba,
    domain: 'wazamba.com',
    feature: '100% Deposit bonus + 200 Free Spins',
    about: [
      "Wazamba is not just an online casino; it's an immersive adventure that combines the thrill of gambling with an engaging narrative set in a jungle world. With its vast game selection, live casino options, loyalty program, and a unique adventure theme, Wazamba offers a fresh and exciting approach to online gaming. Whether you're a casual player looking for some entertainment or a seasoned gambler seeking a new adventure, Wazamba provides an all-encompassing experience that is both entertaining and rewarding.",
      ' Dive into the jungle and explore the exciting world of Wazamba today.',
    ],
    quickFacts: {
      wager: '35x d+b',
      minDeposit: '10 EUR',
      maxDeposit: '5 EUR (When playing with bonus funds)',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'No',
      crypto: 'Yes',
      url: 'https://wzb.trak7s.com/redirect.aspx?pid=2100948&lpid=2044&bid=5103',
    },
  },
  {
    bg: bgSlotamba,
    logo: slotamba,
    domain: 'slotamba.com',
    feature: 'Exclusive 200% Deposit bonus',
    code: 'ROLL',
    about: [
      'Slotamba is an online casino and gaming platform that has been in operation since 2021. It offers a wide array of casino games, including slots, table games, video poker, and live casino options, catering to players in various countries. ',
      ` The platform prides itself on a user-friendly interface and often provides engaging website designs to enhance the gaming experience.
      Similar to other reputable online casinos, Slotamba typically extends various promotions and bonuses to attract new players and reward existing ones, such as welcome bonuses and free spins. They prioritize player safety and security by operating under strict licensing and regulatory frameworks while offering robust customer support services.`,
    ],
    quickFacts: {
      wager: '40x d+b',
      minDeposit: '20 EUR',
      maxDeposit: '12 EUR (or 5% of the bonus, whichever is lower)',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'Yes',
      crypto: 'Yes',
      url: 'https://go.slotambapartners.com/visit/?bta=35345&nci=5363 ',
    },
  },
  {
    bg: bgheycasino,
    logo: heycasino,
    domain: 'heycasino.com',
    feature: '200% Deposit Bonus',
    about: [
      'In the ever-evolving landscape of online entertainment, HeyCasino has emerged as a prominent player in the world of digital gambling and gaming. This innovative platform offers a comprehensive and exciting array of casino games, combining the thrill of traditional brick-and-mortar casinos with the convenience and accessibility of the internet.',
      " HeyCasino has carved out a niche in the online gambling industry by offering a wide range of games, a user-friendly platform, robust security measures, and a commitment to responsible gaming. If you're seeking a thrilling and enjoyable online casino experience, HeyCasino may be your ideal destination.",
    ],
    quickFacts: {
      wager: '30x b',
      minDeposit: '20 EUR',
      maxDeposit: 'No max bet',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'Yes',
      crypto: 'No',
      url: 'https://record.grandeaffiliates.com/_A0ym1eKRVP1hg6WO2I1rgWNd7ZgqdRLk/1/?pg=0 ',
    },
  },
  {
    bg: bgarcanebet,
    logo: arcanebet,
    domain: 'arcanebet.com',
    feature: '100% deposit bonus',
    about: [
      'Arcanebet Casino is a relatively fresh entrant in the online gambling scene, but their concerted efforts have been directed towards appealing to a wide spectrum of players. Whether your preference leans towards video slots, classic casino table games, or engaging video poker titles like Double Jackpot Poker, arcanebet Casino has got you covered. At arcanebet Casino, the gaming library boasts an impressive arsenal of over 3000 slots, sourced from renowned providers such as NoLimit City, Big Time Gaming, and Relax. This Curacao-licensed casino has spared no expense when it comes to selecting top-tier developers, with an esteemed lineup of software providers that include some of the most distinguished names in the industry. ',
      " Customer support at arcanebet Casino is nothing short of dedicated and dependable. Their support team is at your service 24/7, prepared to respond to your queries and provide assistance whenever needed. Furthermore, for newcomers, there's a generous welcome bonus up for grabs as an added incentive.",
    ],
    quickFacts: {
      wager: '35x b',
      minDeposit: '10 EUR',
      maxDeposit: '5 EUR ',
      vpnAllowed: 'Yes (only for accessibility of games) ',
      bonus: '(Balance must be above €1.00 at all times)',
      raw: 'Yes',
      crypto: 'Yes',
      url: 'https://www.arcanebet.com/en/welcome?btag=17674326_237782',
    },
  },
];

const bannerInfo = {
  logo: fj_logo,
  domain: "fortunejack.com",
  about: [
    "FortuneJack is a well-established and reputable online gambling platform that has gained popularity among cryptocurrency enthusiasts and avid gamblers. Launched in 2014, FortuneJack has successfully combined the world of cryptocurrency with the excitement of casino gaming and sports betting.",
    "Players can deposit, wager, and withdraw funds using popular digital currencies such as Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC), and more. This focus on crypto transactions ensures fast, secure, and anonymous betting experiences.",
    "FortuneJack offers generous bonuses and promotions to both new and existing players. These incentives can include deposit bonuses, free spins, cashback rewards, and special promotions tied to specific events or holidays.",
  ],
  code: "CLOVER",
  quickFacts: {
    wager: "35x bonus",
    minDeposit:
      "$30 (*To claim the 500 free spins, you must deposit min. $150)",
    maxDeposit: "No max bet",
    vpnAllowed: "Yes",
    bonus: "Yes (Balance must be above $1.00 at all times)",
    raw: "Yes",
    crypto: "Yes",
    url: "https://tracker-pm2.fortunejackpartners.com/link?btag=49422327_269479",
  },
};

const Banners = () => {
  const navigateTo = () => {
    window.location.href =
      "https://tracker-pm2.fortunejackpartners.com/link?btag=49422327_269479";
  };

  return (
    <section
      id='banners'
      className='mt-[70px] flex items-center justify-center '
    >
      <div className='w-[1170px] flex flex-col  gap-8 px-4'>
        <div className='h-[290px] rounded-3xl bg-[#151822] relative overflow-hidden grid lg:grid-cols-2 '>
          {/* Images on left  */}
          <div className='images col-span-1'>
            <img
              src={background}
              alt='background'
              className=' w-full h-64 absolute -top-4 lg:-top-7 object-cover'
            />
            <img
              src={characterMen}
              alt='men'
              className='absolute z-20 scale-x-[-1] h-32 -left-32 top-8 lg:scale-x-[1] lg:h-full lg:left-0 lg:top-0'
            />
            <img
              src={characterDecor}
              alt='decor'
              className='absolute z-20 h-32 bottom-24 left-6 md:hidden'
            />
            <img
              src={characterWomen}
              alt='women'
              className='absolute z-20 h-32 bottom-12 -left-32 lg:h-full lg:bottom-0 lg:left-0'
            />
          </div>
          {/* Text on right  */}
          <div className='text lg:col-start-2 absolute z-20 w-full'>
            <div className='flex justify-between pt-8 pr-8'>
              <img
                src={fj_logo}
                alt='Full Jack Logo'
                className='absolute left-32 lg:static'
              />
              <InfoIcon
                position='absolute'
                logo={fj_logo}
                domain='fortunejack.com'
                about={bannerInfo.about}
                code={bannerInfo.code}
                quickFacts={bannerInfo.quickFacts}
              />
            </div>

            <div className='relative text-white  py-6 lg:flex lg:flex-col lg:gap-2'>
              <h1
                className='text-[23px] absolute lg:static left-32 mt-4'
                style={{
                  textAlign: 'left',
                  fontFamily: 'ClashGrotesk-bold, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '700',

                  letterSpacing: '2.15px',
                  textTransform: 'uppercase',
                }}
              >
                150% NO-Sticky Bonus + 500 Free Spins
              </h1>
              <h2
                className='text-sm lg:text-[20px] font-bold lg:static absolute left-32 top-[145px] text-[#45FF79] lg:text-white'
                style={{
                  textAlign: 'left',
                  fontFamily: 'ClashGrotesk-regular, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  letterSpacing: '2.15px',
                  textTransform: 'uppercase',
                }}
              >
                CODE: CLOVER
              </h2>
            </div>
            <div className=' absolute z-10 top-[203px] left-16 sm:left-28 lg:top-[190px] lg:-left-2  '>
              <div>
                <div className='hidden sm:flex'>
                  <Button
                    title='Claim Now'
                    icon={btnIcon}
                    iconStyles='py-4 px-12 w-[235px]'
                    onclick={() => navigateTo()}
                  />
                </div>
                <div className='flex sm:hidden'>
                  <Button
                    title='Claim Now'
                    styles='py-3 px-10  w-[235px]'
                    onclick={() => navigateTo()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='grid md:grid-cols-4 gap-4 '>
          {cardInfo.map((info, index) => {
            return <Card {...info} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Banners;
