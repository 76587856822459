import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";

import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import Stream from "./pages/Stream";

function App() {
  return (
    <div className="bg-[#172030] overflow-x-hidden">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/stream" element={<Stream />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
