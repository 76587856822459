import FooterDown from "./FooterDown";
import FooterUp from "./FooterUp";

const Footer = () => {
  return (
    <footer className='bg-[#151822] px-4 lg:px-40 py-12 mt-32 relative flex items-center justify-center'>
      <div className='sm:w-[1170px]'>
        <FooterUp />
        <FooterDown />
      </div>
    </footer>
  );
};

export default Footer;
