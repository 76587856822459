import Hero from "../components/home/hero/Hero";
import Banners from "../components/home/banner/Banners";
import Selection from "../components/home/selection/Selection";
import CasinoChoice from "../components/home/casino_choice/CasinoChoice";
import RightChoice from "../components/home/right_choice/RightChoice";

const Home = () => {
  return (
    <div className='App lg:px-40'>
      <section id='Hero'>
        <Hero />
      </section>
      <section id='Banners'>
        <Banners />
      </section>
      <section id='Selection'>
        <Selection />
      </section>
      <section id='CasinoChoice'>
        <CasinoChoice />
      </section>
      <section id='RightChoice'>
        <RightChoice />
      </section>
    </div>
  );
};

export default Home;
