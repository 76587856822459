const Button = ({ title, icon, styles, iconStyles, isList, onclick }) => {
  return (
    <button
      className={`bg-[#45FF79] border-8 border-[#151822]  rounded-[50px]  text-[#151822]  font-[600] text-[16px] hover:shadow-[0_0px_20px_0px_rgba(69,255,121,.5)] ${
        icon ? `flex justify-between ${iconStyles}  items-center` : ` ${styles}`
      }`}
      style={{
        fontFamily: "ClashGrotesk-medium, sans-serif",
        // padding: "16px 20px",
        width: isList ? "265px" : null,
      }}
      onClick={() => onclick()}
    >
      <span>{title}</span>
      {icon && <img src={icon} alt="icon" />}
    </button>
  );
};

export default Button;
