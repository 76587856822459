import { useEffect, useState } from "react";

import Button from "../../Button";
import InfoIcon from "../../InfoIcon";
import btnIcon from "../../../assets/banner/btn_icon.png";
import toOpenIcon from "../../../assets/selection/shared/toOpenIcon.png";
import toCloseIcon from "../../../assets/selection/shared/toCloseIcon.png";

const SelectionCard = ({
  logo,
  name,
  domain,
  feature,
  paymentMethods,
  about,
  quickFacts,
}) => {
  const handleClick = (url) => {
    window.location.href = url;
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenFeature = () => {
    setIsOpen(!isOpen);
  };

  const isFeatureOpen = isOpen ? "selection-card" : "";
  const isToggleOpen = isOpen ? toCloseIcon : toOpenIcon;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  }, []);

  return (
    <div
      className={` ${isFeatureOpen} bg-[#151822] rounded-xl px-1 lg:px-5 lg:hover:shadow-[0_0px_20px_15px_rgba(126,117,28,1)]`}
    >
      {/* DESKTOP VIEW  */}
      <div className=" flex justify-between items-center gap-5  h-24 ">
        {/* LOGO */}
        <div className="bg-[#151822] flex pl-5 items-center  h-16  flex-[10%] ">
          <img src={logo} alt="logo" />
        </div>

        {/* FEATURE TOGGLE ICON  */}
        <img
          onClick={handleOpenFeature}
          src={isToggleOpen}
          alt="Open Icon"
          className={`cursor-pointer h-10 ${
            isOpen ? "w-8" : "w-6"
          } mr-[25px] lg:mr-0  lg:hidden`}
        />

        {/* Name and Domain  */}
        <div className="hidden lg:flex flex-[50%] lg:gap-[25px]">
          <div
            className="flex flex-col justify-center flex-1 h-14 pl-10"
            style={{
              borderLeft: "rgba(205,212,238, .1) solid 2px",
            }}
          >
            <h1
              style={{
                color: "#45FF79",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "26.4px",
                letterSpacing: "1px",
              }}
            >
              {name}
            </h1>
            <p
              className=" text-lg"
              style={{
                fontFamily: "ClashGrotesk-extralight, sans-serif",
                color: "rgba(205,212,238,.7)",
              }}
            >
              {domain}
            </p>
          </div>

          <div
            className="bg-[#ffffff08]  flex justify-center items-center text-center text-[16px] flex-1 h-16 font-medium rounded-xl "
            style={{
              fontFamily: "ClashGrotesk-regular, sans-serif",
              color: "rgba(205,212,238,.7)",
            }}
          >
            <p className="px-3 feature">{feature}</p>
          </div>

          <div className="bg-[#ffffff08]  flex-1/2 px-4 mx-2 h-16 flex items-center justify-center gap-2 rounded-xl">
            <div className="flex items-center gap-2 h-full ">
              {paymentMethods.map(({ imgSrc, alt }, index) => {
                return (
                  <img src={imgSrc} alt={alt} className="w-5 h-5" key={index} />
                );
              })}
            </div>
            <span
              className=" text-white text-[15px] pl-2"
              style={{
                borderLeft: "rgba(205,212,238, .1) solid 2px",
                fontFamily: "ClashGrotesk-bold, sans-serif",
              }}
            >
              +4
            </span>
          </div>
        </div>

        {/* Features  */}
        <div className="flex gap-2 lg:flex-[5%]">
          <div className=" flex justify-center items-center  flex-1 ">
            <InfoIcon
              position="static"
              logo={logo}
              domain={domain}
              about={about}
              quickFacts={quickFacts}
            />
          </div>

          <div className="lg:flex-1 self-center">
            {screenWidth >= 768 ? (
              <Button
                title="Claim your reward now"
                icon={btnIcon}
                iconStyles=" py-3 px-6 text-[13px] font-semibold tracking-wide hover:bg-[#06FD4B] hover:shadow-none"
                isList={true}
                onclick={() => handleClick(quickFacts.url)}
              />
            ) : (
              <Button
                title="Claim Now"
                styles={"hover:shadow-none hover:bg-[#06FD4B] px-4 py-3"}
                isList={false}
                onclick={() => handleClick(quickFacts.url)}
              />
            )}
          </div>
        </div>
      </div>

      {/* MOVILE VIEW  */}
      {isOpen && (
        <div
          className=" lg:hidden mx-2 pt-4 "
          style={{
            borderTop: "rgba(205,212,238, .1) solid 2px",
          }}
        >
          <div className="flex gap-2 lg:hidden self-center">
            <div className="flex flex-col justify-center flex-1 ">
              <h1
                style={{
                  color: "#45FF79",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "26.4px",
                }}
              >
                {name}
              </h1>
              <p
                className=" text-sm"
                style={{
                  fontFamily: "ClashGrotesk-extralight, sans-serif",
                  color: "rgba(205,212,238,.7)",
                }}
              >
                {domain}
              </p>
            </div>

            <div
              className="bg-[#ffffff08] px-1 flex flex-1 justify-center items-center  rounded-xl"
              style={{
                fontFamily: "ClashGrotesk-regular, sans-serif",
                color: "rgba(205,212,238,.7)",
              }}
            >
              <p className="text-center text-[10px]  font-bold">{feature}</p>
            </div>

            <div className="bg-[#ffffff08]   px-2 flex flex-1 items-center justify-center gap-2 rounded-xl">
              <div className="flex items-center gap-2 h-full ">
                {paymentMethods.map(({ imgSrc, alt }, index) => {
                  return (
                    <img
                      src={imgSrc}
                      alt={alt}
                      className="h-3 w-3 lg:w-5 lg:h-5"
                      key={index}
                    />
                  );
                })}
              </div>
              <span
                className=" text-white text-[12px] pl-2"
                style={{
                  borderLeft: "rgba(205,212,238, .1) solid 2px",
                  fontFamily: "ClashGrotesk-bold, sans-serif",
                }}
              >
                +4
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectionCard;
