import React, { useEffect } from "react";

const TwitchEmbed = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.twitch.tv/embed/v1.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      new window.Twitch.Embed("twitch-embed", {
        width: "100%",
        height: 480,
        channel: "luckyrolltv",
        // Only needed if this page is going to be embedded on other websites
        parent: ["embed.example.com", "othersite.example.com"],
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex justify-center px-4">
      <div
        id="twitch-embed"
        className=" w-[1100px] h-[480px] overflow-hidden "
      />
    </div>
  );
};

export default TwitchEmbed;
