import React from "react";
import Button from "../../Button";
import btnIcon from "../../../assets/banner/btn_icon.png";

const QuickFacts = ({ quickFacts }) => {

  const navigateTo = (url) => {
    window.location.href =
    url;
  };
  return (
    <div className="lg:w-[419px] bg-[#EAEEFE] p-12 flex flex-col gap-4">
      <h1
        className="text-lg uppercase"
        style={{
          fontFamily: "ClashGrotesk-bold, sans-serif",
          letterSpacing: "1px",
        }}
      >
        Quick Facts
      </h1>

      <div className="flex flex-col gap-1">
        <p className="text-[17px] ">
          <span
            style={{
              fontFamily: "ClashGrotesk-semibold, sans-serif",
            }}
          >
            Wager:
          </span>{" "}
          {quickFacts.wager}
        </p>
        <p className="text-[17px] ">
          <span
            style={{
              fontFamily: "ClashGrotesk-semibold, sans-serif",
            }}
          >
            Min. Deposit:
          </span>{" "}
          {quickFacts.minDeposit}
        </p>
        <p className="text-[17px] ">
          <span
            style={{
              fontFamily: "ClashGrotesk-semibold, sans-serif",
            }}
          >
            Max. Bet:
          </span>{" "}
          {quickFacts.maxDeposit}
        </p>
        <p className="text-[17px] ">
          <span
            style={{
              fontFamily: "ClashGrotesk-semibold, sans-serif",
            }}
          >
            VPN Allowed:
          </span>{" "}
          {quickFacts.vpnAllowed}
        </p>
        <p className="text-[17px] ">
          <span
            style={{
              fontFamily: "ClashGrotesk-semibold, sans-serif",
            }}
          >
            Bonus Hunt Allowed:
          </span>{" "}
          {quickFacts.bonus}
        </p>
        <p className="text-[17px] ">
          <span
            style={{
              fontFamily: "ClashGrotesk-semibold, sans-serif",
            }}
          >
            Bonus Hunt Allowed (RAW):
          </span>{" "}
          {quickFacts.raw}
        </p>
        <p className="text-[17px] ">
          <span
            style={{
              fontFamily: "ClashGrotesk-semibold, sans-serif",
            }}
          >
            Cryptocurrencies Allowed:
          </span>{" "}
          {quickFacts.crypto}
        </p>
      </div>

      <Button
        title="Get your bonuses"
        icon={btnIcon}
        iconStyles="py-3 px-6 mt-6 text-[13px] font-semibold tracking-wide w-[235px] hover:bg-[#06FD4B] hover:shadow-none border-none"
        onclick={() => navigateTo(quickFacts.url)}
      />
    </div>
  );
};

export default QuickFacts;
