import React from "react";
import RenderLogo from "./RenderLogo";

const RenderText = () => {
  return (
    <>
      <h1
        className="text-center lg:text-left text-4xl lg:text-[45px]  "
        style={{
          color: "#FFF",
          fontFamily: "ClashGrotesk-bold, sans-serif",

          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "50px",
          letterSpacing: "2.15px",
          textTransform: "uppercase",
        }}
      >
        OUR CASINO CHOICE FOR{" "}
        <span className="text-[#45FF79]">SEPTEMBER 2023</span>
      </h1>
      <div
        className="flex lg:hidden rounded-xl z-10  justify-center items-center h-56"
        style={{
          background: "rgba(32, 36, 53, 0.65)",
          boxShadow: "0px 10px 40px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <RenderLogo />
      </div>
      <p
        className="text-justify lg:text-left"
        style={{
          color: "rgba(255,255,255,.8)",
          fontFamily: "ClashGrotesk-extralight, sans-serif",
          fontSize: "17px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26.4px",
        }}
      >
        Congratulations to 
        <span className="text-[#45FF79]">fortunejack.com</span> for winning our
        Casino Listing of the Month! 
      </p>

      <p
        className="text-justify lg:text-left"
        style={{
          color: "rgba(255,255,255,.8)",
          fontFamily: "ClashGrotesk-extralight, sans-serif",
          fontSize: "17px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "26.4px",
        }}
      >
        FortuneJack is a Curacao-licensed online casino that launched in 2014
        and is operated by the Nexus Group, a small but highly respected
        operator. It is a stylish, well-designed casino that is fully supported
        on mobile devices and also has a dedicated desktop site.
      </p>
    </>
  );
};

export default RenderText;
