// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

import promotion from "../../../assets/right_choice/promotion.gif";
import payments from "../../../assets/right_choice/payments.gif";
import security from "../../../assets/right_choice/security.gif";

import React from "react";

export const RenderDesktopCarousel = () => {
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={3}
      initialSlide={1}
      coverflowEffect={{
        rotate: 0,
        stretch: 160,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={{
        clickable: true, // Enable clickable pagination
        type: "bullets", // Set pagination type to 'bullets'
      }}
      modules={[EffectCoverflow, Pagination]}
      className="mySwiper "
    >
      <SwiperSlide>
        <div className="card flex flex-col rounded-xl bg-[#151822]  items-center justify-center w-[410px] h-full ">
          <img src={security} alt="Icon" className="w-32 h-32 rounded-full" />
          <div className="text-center">
            <h1
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "ClashGrotesk-bold, sans-serif",
                fontSize: "30.437px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "50px",
                letterSpacing: "2.15px",
                textTransform: "uppercase",
              }}
            >
              SECURITY
            </h1>
            <h2
              className="mb-[25px]"
              style={{
                color: "#45FF79",
                fontSize: "12.936px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "20.088px",
                letterSpacing: "1px",
              }}
            >
              Safety First!
            </h2>
          </div>
          <p
            className=" text-[16px] mx-8 lg:mx-[35px] text-justify lg:text-center"
            style={{
              fontFamily: "ClashGrotesk-regular, sans-serif",
              color: "rgba(205,212,238,.7)",
              lineHeight: "26.4px",
            }}
          >
            Playing in a safe environment is a major priority! Your casino
            account's security matters, alongside a trusted brand with the right
            license and reputable game suppliers. LuckyRollTV guarantees
            listings of licensed, regulated brands for a secure and enjoyable
            experience. No compromise on safety; our selection is meticulous and
            compliant.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card flex flex-col  rounded-xl bg-[#151822]  items-center z-10 h-full lg:h-[490px]">
          <img
            src={promotion}
            alt="Icon"
            className="w-[136px] h-[136px] rounded-full flex-shrink-0 my-[22px]"
          />
          <div className="text-center">
            <h1
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "ClashGrotesk-bold, sans-serif",
                fontSize: "40px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "50px",
                letterSpacing: "2.15px",
                textTransform: "uppercase",
              }}
            >
              PROMOTIONS
            </h1>
            <h2
              className="mb-[35px]"
              style={{
                color: "#45FF79",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "26.4px",
                letterSpacing: "1px",
              }}
            >
              Let's talk about bonuses...
            </h2>
          </div>
          <p
            className=" text-[17px] mx-8 lg:mx-[46px] text-justify lg:text-center "
            style={{
              fontFamily: "ClashGrotesk-regular, sans-serif",
              color: "rgba(205,212,238,.7)",
              lineHeight: "26.4px",
            }}
          >
            Online casinos offer attractive welcome bonuses to entice new
            customers. Our selected casinos go beyond with cashback promos,
            weekly rewards, Free Spins, and more, giving you ample choices. Play
            with bonuses or cash – the choice is yours!
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card flex flex-col gap-4 rounded-xl  bg-[#151822]  items-center  justify-center w-[410px] h-full py-10 lg:py-0">
          <img src={payments} alt="Icon" className="w-32 h-32 rounded-full" />
          <div className="text-center">
            <h1
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "ClashGrotesk-bold, sans-serif",
                fontSize: "30.437px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "50px",
                letterSpacing: "2.15px",
                textTransform: "uppercase",
              }}
            >
              PAYMENT METHODS
            </h1>
            <h2
              style={{
                color: "#45FF79",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "26.4px",
                letterSpacing: "1px",
              }}
            >
              Deposits and withdrawals
            </h2>
          </div>
          <p
            className=" text-[16px] mx-14 lg:mx-10 text-justify lg:text-center"
            style={{
              fontFamily: "ClashGrotesk-regular, sans-serif",
              color: "rgba(205,212,238,.7)",
              lineHeight: "26.4px",
            }}
          >
            Efficient and secure deposits are vital for any online casino. Our
            focus is on offering a wide range of major payment methods (Visa,
            Mastercard, Interac, MuchBetter, Bitcoin, e-wallets, etc.). Equally
            significant is swift withdrawal capability. The listed casinos
            ensure rapid and dependable processes. While safety is paramount,
            quick access to winnings is also prioritized.
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export const RenderMobileCarousel = () => {
  return (
    <Swiper
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={1}
      initialSlide={1}
      pagination={{
        clickable: true, // Enable clickable pagination
        type: "bullets", // Set pagination type to 'bullets'
      }}
      modules={[Pagination]}
      className="mySwiperMobile "
    >
      <SwiperSlide>
        <div className="card flex flex-col rounded-xl bg-[#151822]  items-center justify-center w-[410px] h-full  ">
          <img src={security} alt="Icon" className="w-32 h-32 rounded-full" />
          <div className="text-center">
            <h1
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "ClashGrotesk-bold, sans-serif",
                fontSize: "30.437px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "50px",
                letterSpacing: "2.15px",
                textTransform: "uppercase",
              }}
            >
              SECURITY
            </h1>
            <h2
              className="mb-[25px]"
              style={{
                color: "#45FF79",
                fontSize: "12.936px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "20.088px",
                letterSpacing: "1px",
              }}
            >
              Safety First!
            </h2>
          </div>
          <p
            className=" text-[16px] mx-8 lg:mx-[35px] text-justify lg:text-center"
            style={{
              fontFamily: "ClashGrotesk-regular, sans-serif",
              color: "rgba(205,212,238,.7)",
              lineHeight: "26.4px",
            }}
          >
            Playing in a safe environment is a major priority! Your casino
            account's security matters, alongside a trusted brand with the right
            license and reputable game suppliers. LuckyRollTV guarantees
            listings of licensed, regulated brands for a secure and enjoyable
            experience. No compromise on safety; our selection is meticulous and
            compliant.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card flex flex-col  rounded-xl bg-[#151822]  items-center z-10 h-full lg:h-[490px]">
          <img
            src={promotion}
            alt="Icon"
            className="w-[136px] h-[136px] rounded-full flex-shrink-0 my-[22px]"
          />
          <div className="text-center">
            <h1
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "ClashGrotesk-bold, sans-serif",
                fontSize: "40px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "50px",
                letterSpacing: "2.15px",
                textTransform: "uppercase",
              }}
            >
              PROMOTIONS
            </h1>
            <h2
              className="mb-[35px]"
              style={{
                color: "#45FF79",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "26.4px",
                letterSpacing: "1px",
              }}
            >
              Let's talk about bonuses...
            </h2>
          </div>
          <p
            className=" text-[17px] mx-8 lg:mx-[46px] text-justify lg:text-center "
            style={{
              fontFamily: "ClashGrotesk-regular, sans-serif",
              color: "rgba(205,212,238,.7)",
              lineHeight: "26.4px",
            }}
          >
            Online casinos offer attractive welcome bonuses to entice new
            customers. Our selected casinos go beyond with cashback promos,
            weekly rewards, Free Spins, and more, giving you ample choices. Play
            with bonuses or cash – the choice is yours!
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="card flex flex-col gap-4 rounded-xl  bg-[#151822]  items-center  justify-center w-[410px] h-full py-10 lg:py-0">
          <img src={payments} alt="Icon" className="w-32 h-32 rounded-full" />
          <div className="text-center">
            <h1
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "ClashGrotesk-bold, sans-serif",
                fontSize: "30.437px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "50px",
                letterSpacing: "2.15px",
                textTransform: "uppercase",
              }}
            >
              PAYMENT METHODS
            </h1>
            <h2
              style={{
                color: "#45FF79",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "26.4px",
                letterSpacing: "1px",
              }}
            >
              Deposits and withdrawals
            </h2>
          </div>
          <p
            className=" text-[16px] mx-8 lg:mx-10 text-justify lg:text-center"
            style={{
              fontFamily: "ClashGrotesk-regular, sans-serif",
              color: "rgba(205,212,238,.7)",
              lineHeight: "26.4px",
            }}
          >
            Efficient and secure deposits are vital for any online casino. Our
            focus is on offering a wide range of major payment methods (Visa,
            Mastercard, Interac, MuchBetter, Bitcoin, e-wallets, etc.). Equally
            significant is swift withdrawal capability. The listed casinos
            ensure rapid and dependable processes. While safety is paramount,
            quick access to winnings is also prioritized.
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
