import React from "react";
import RenderLogo from "./RenderLogo";
import RenderText from "./RenderText";

import background from "../../../assets/casino_choice/background.gif";

const CasinoChoice = () => {
  return (
    <div className='flex justify-center items-center'>
      <div className='casino-choice-container min-h-[430px] my-32  rounded-3xl py-14 px-4 lg:p-14 relative overflow-hidden sm:w-[1120px]  '>
        <img
          src={background}
          alt='background'
          className='absolute w-full h-full opacity-25 top-0 left-0'
        />

        <div
          className='hidden xl:flex rounded-xl lg:mr-14 z-10  justify-center items-center'
          style={{
            background: 'rgba(32, 36, 53, 0.65)',
            boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <RenderLogo />
        </div>
        <div className=' z-10 py-8 text-white flex flex-col gap-8 xl:gap-0 lg:w-[610px] justify-between '>
          <RenderText />
        </div>
      </div>
    </div>
  );
};

export default CasinoChoice;
