import disclaimer from "../../assets/footer/disclaimer.png";
import gambleAware from "../../assets/footer/gambleAware.png";

const FooterUp = () => {
  return (
    <div className=' mb-20'>
      <div className='flex justify-between px-2 mb-8'>
        <img src={disclaimer} alt='disclaimer' className='w-32 h-7' />
        <img src={gambleAware} alt='gambleAware' className='w-32 h-5' />
      </div>
      <p
        className='text-justify lg:text-left'
        style={{
          fontFamily: 'ClashGrotesk-regular, sans-serif',
          color: 'rgba(205,212,238,.7)',
          fontSize: '16px',
          letterSpacing: '1.5px',
          lineHeight: '26.4px',
        }}
      >
        We do not take responsibility for any losses from gameplay on casinos
        and entertainment websites promoted on our site. Please bet responsibly
        and only bet or wager with money you can afford to lose and do not chase
        your losses. Follow jurisdictional law(s) in accordance with your
        location prior to registering and gameplay.
      </p>
    </div>
  );
};

export default FooterUp;
