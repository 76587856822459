import { RenderDesktopCarousel, RenderMobileCarousel } from "./RenderCarousel";
import { useMediaQuery } from "@react-md/utils";

const Carousel = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className='swiper-container flex justify-center relative w-[1500px] '>
      {isMobile ? <RenderMobileCarousel /> : <RenderDesktopCarousel />}

      <div className='absolute bottom-2 flex w-[150px] gap-[22px] left-1/2 -translate-x-1/2'>
        <div className='ml-[18px] mt-[1px] bg-[#cdd4ee] w-[13px] h-[13px] rounded-full'></div>
        <div className='ml-[5.9px] mb-2 bg-[#cdd4ee] w-[13px] h-[13px] rounded-full'></div>
        <div className='ml-[6px] bg-[#cdd4ee] w-[13px] h-[13px] rounded-full'></div>
      </div>
    </div>
  );
};

export default Carousel;
