import Button from "../../Button";
import InfoIcon from "../../InfoIcon";

const Card = ({ bg, logo, domain, feature, code, about, quickFacts }) => {
  const handleClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="h-[300px] bg-[#151822] rounded-3xl relative flex flex-col items-center p-6 gap-4 overflow-hidden">
      <img src={bg} alt="Background" className="absolute h-60 w-full top-0" />

      <div className="flex items-center justify-center h-30 w-[110px] z-10">
        <img src={logo} alt="logo" className="" />
        <InfoIcon
          position="absolute"
          logo={logo}
          domain={domain}
          about={about}
          quickFacts={quickFacts}
        />
      </div>

      <div className="z-10  ">
        <h1
          className="text-2xl leading-tight py-8 lg:py-0"
          style={{
            color: "#FFF",
            textAlign: "center",
            fontFamily: "ClashGrotesk-bold, sans-serif",
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "2px",
            textTransform: "uppercase",
          }}
        >
          {feature}
        </h1>
        {code ? (
          <h2
            className="text-center text-[#45FF79]"
            style={{ letterSpacing: "2px" }}
          >
            Code: {code}
          </h2>
        ) : null}
      </div>

      <div className="absolute z-10 bottom-8">
        <Button
          title="Claim Now"
          styles="py-3 px-10  w-[235px]"
          onclick={() => handleClick(quickFacts.url)}
        />
      </div>
    </div>
  );
};

export default Card;
