import Button from "../../Button";

export default function Example() {
  const handleClickScroll = (sectionId) => (event) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="lg:h-[calc(75vh-100px)] flex flex-col justify-center items-center "
      id="hero"
    >
      <div className="text-center flex flex-col gap-[40px] items-center pt-10 lg:w-[1170px] ">
        <h1
          className="text-white text-center text-[40px] lg:text-[55px] lg:mt-[101px]"
          style={{
            fontFamily: "ClashGrotesk-bold, sans-serif",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "50px",
            letterSpacing: "2.15px",
            textTransform: "uppercase",
          }}
        >
          TOP ONLINE CASINOS TO PLAY
        </h1>

        <p
          className="text-[#CDD4EE] text-justify px-4 text-[17px] font-normal leading-[26.4px] opacity-[0.7] lg:text-center lg:px-[160px] "
          style={{ fontFamily: "ClashGrotesk-extralight, sans-serif" }}
        >
          Welcome to LuckyRollTV, the premier online casino review and streaming
          platform! Our website is dedicated to helping you find the best online
          casinos. We provide detailed reviews and ratings of the top online
          casinos to make sure you can make an informed decision before you
          start playing. We also offer advice on how to make the most of your
          online casino experience and the latest news and promotions. Plus, we
          showcase the websites we advertise by streaming them on multiple
          platforms such as Twitch & YouTube, so you can get a glimpse into the
          casinos before you even sign up. Join us today and start playing at
          the best online casinos!
        </p>

        <Button
          title="Go To Casino"
          styles="py-3 px-10  w-[235px]"
          onclick={handleClickScroll("Selection")}
        />
      </div>
    </section>
  );
}
