// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
  padding: 0 0 100px 0;
}

.mySwiperMobile {
  width: 360px;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: white;
  margin-right: 22px !important;
}

.swiper-pagination-bullet-active {
  background-color: green;
}

.swiper-slide {
  background-position: center;
  display: flex;
  align-items: center;
  width: 539px;
}

.swiper-slide .card {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .swiper-slide {
    width: 360px;
    height: 100%;
  }
  .swiper-slide .card {
    padding: 20px 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/right_choice/styles.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".swiper {\n  padding: 0 0 100px 0;\n}\n\n.mySwiperMobile {\n  width: 360px;\n}\n\n.swiper-pagination-bullet {\n  width: 15px;\n  height: 15px;\n  background-color: white;\n  margin-right: 22px !important;\n}\n\n.swiper-pagination-bullet-active {\n  background-color: green;\n}\n\n.swiper-slide {\n  background-position: center;\n  display: flex;\n  align-items: center;\n  width: 539px;\n}\n\n.swiper-slide .card {\n  width: 100%;\n}\n\n@media screen and (max-width: 768px) {\n  .swiper-slide {\n    width: 360px;\n    height: 100%;\n  }\n  .swiper-slide .card {\n    padding: 20px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
