import { Dialog } from "@react-md/dialog";
import SiteName from "./SiteName";
import About from "./About";
import QuickFacts from "./QuickFacts";
import closeRing from "../../../assets/close_ring.png";

const Modal = ({ visible, disable, logo, domain, about, quickFacts }) => {
  return (
    <Dialog
      className="fixed top-0 lg:top-1/2 lg:left-1/2 z-20 rounded-xl overflow-scroll lg:overflow-hidden lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-[1170px] h-[100vh] lg:h-fit flex flex-col lg:flex-row"
      id="dialog"
      visible={visible}
      onRequestClose={disable}
      aria-labelledby="dialog"
    >
      <SiteName logo={logo} domain={domain} />
      <About about={about} />
      <QuickFacts quickFacts={quickFacts} />

      <button
        id="dialog-close"
        onClick={disable}
        className="absolute top-10 right-10"
      >
        <img src={closeRing} alt="close ring" />
      </button>
    </Dialog>
  );
};

export default Modal;
