import React from "react";
import { Link } from "react-router-dom";
import twitter from "../../assets/navbar/twitter.png";
import youtube from "../../assets/navbar/youtube.png";
import discord from "../../assets/navbar/discord.png";

const navigation = [
  { name: "Home", goto: "/" },
  { name: "Stream", goto: "/stream" },
];

const FooterDown = () => {
  return (
    <div>
      <div className='hidden sm:flex items-center justify-center'>
        <div className='absolute bg-black bottom-0 left-0 w-full h-20 text-white flex justify-center items-center px-4 lg:px-40 '>
          <div className='flex  justify-between items-center sm:w-[1170px]'>
            <p>affiliates@luckyroll.tv</p>
            <nav className='flex gap-8'>
              {navigation.map(({ name, goto }, index) => {
                return (
                  <Link to={goto} key={index} className={`text-lg underline`}>
                    {name}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
      <div className='block sm:hidden'>
        <div className='absolute bg-black bottom-0 left-0 w-full h-28 text-white  flex justify-between items-center px-4 lg:px-40 '>
          <div className='flex flex-col justify-between '>
            <p className='mt-4 mb-2'>affiliates@luckyroll.tv</p>
            <nav className='flex gap-8 '>
              {navigation.map(({ name, goto }, index) => {
                return (
                  <Link to={goto} key={index} className={`text-lg underline`}>
                    {name}
                  </Link>
                );
              })}
            </nav>
          </div>
          <div className='flex gap-2'>
            <a
              href='https://twitter.com/LuckyRollTV'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={twitter}
                alt='Icon'
                className='w-[13.176px] h-[13.176px] rounded-full'
              />
            </a>
            <a
              href='https://www.youtube.com/@LuckyRollTV'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={youtube}
                alt='Icon'
                className='w-[13.176px] h-[13.176px] rounded-full'
              />
            </a>
            <a
              href='https://discord.gg/nUaHnqGJVV'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={discord}
                alt='Icon'
                className='w-[13.176px] h-[13.176px] rounded-full'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterDown;
