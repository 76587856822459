import React from "react";
import RenderTitle from "./RenderTitle";
import Carousel from "./Carousel";

const RightChoice = () => {
  return (
    <div className='flex flex-col items-center gap-20 py-16 '>
      <RenderTitle />
      <Carousel />
    </div>
  );
};

export default RightChoice;
