import React from "react";
import dice from "../../../assets/selection/dice.png";

// FORTUNE JACK IMPORTS
import fjack from "../../../assets/selection/fortunejack/logo.png";
import tether from "../../../assets/selection/fortunejack/tether.png";

// MOI IMPORTS
import moi from "../../../assets/selection/moi/logo.png";
import sofort from "../../../assets/selection/moi/sofort.png";
import mifinity from "../../../assets/selection/moi/mifinity.png";

// RABONA IMPORTS
import rabona from "../../../assets/selection/rabona/logo.png";

// NOMINI IMPORTS
import nomini from "../../../assets/selection/nomini/logo.png";

// WINZ.IO IMPORTS
import winz from "../../../assets/selection/winz.io/logo.png";

// WAZAMBA IMPORTS
import wazamba from "../../../assets/selection/wazamba/logo.png";

// SLOTAMBA IMPORTS
import slotamba from "../../../assets/selection/slotamba/logo.png";

// HEY CASINO IMPORTS
import heycasino from "../../../assets/selection/heycasino/logo.png";
import paysafe from "../../../assets/selection/heycasino/paysafe.png";

// ARCANEBET IMPORTS
import arcanebet from "../../../assets/selection/arcanebet/logo.png";

// SHARED IMPORTS
import visa from "../../../assets/selection/shared/visa.png";
import mastercard from "../../../assets/selection/shared/MasterCard.png";
import bitcoin from "../../../assets/selection/shared/bitcoin.png";
import etheruem from "../../../assets/selection/shared/ethereum.png";
import skrill from "../../../assets/selection/shared/skrill.png";
import litecoin from "../../../assets/selection/shared/litecoin.png";
import interac from "../../../assets/selection/shared/interac.png";
import bank from "../../../assets/selection/shared/bank.png";

import SelectionCard from "./SelectionCard";

const selectionData = [
  {
    logo: fjack,
    name: 'FortuneJack',
    domain: 'fortunejack.com',
    feature: '150% Deposit Bonus + 500 Free Spins',
    paymentMethods: [
      {
        imgSrc: bitcoin,
        alt: 'Bitcoin',
      },
      {
        imgSrc: etheruem,
        alt: 'Etheruem',
      },
      {
        imgSrc: litecoin,
        alt: 'litecoin',
      },
      {
        imgSrc: tether,
        alt: 'tether',
      },
    ],
    about: [
      'FortuneJack is a well-established and reputable online gambling platform that has gained popularity among cryptocurrency enthusiasts and avid gamblers. Launched in 2014, FortuneJack has successfully combined the world of cryptocurrency with the excitement of casino gaming and sports betting.',
      'Players can deposit, wager, and withdraw funds using popular digital currencies such as Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC), and more. This focus on crypto transactions ensures fast, secure, and anonymous betting experiences.',
      'FortuneJack offers generous bonuses and promotions to both new and existing players. These incentives can include deposit bonuses, free spins, cashback rewards, and special promotions tied to specific events or holidays.',
    ],
    quickFacts: {
      wager: '35x bonus',
      minDeposit:
        '$30 (*To claim the 500 free spins, you must deposit min. $150)',
      maxDeposit: 'No max bet',
      vpnAllowed: 'Yes',
      bonus: 'Yes (Balance must be above $1.00 at all times)',
      raw: 'Yes',
      crypto: 'Yes',
      url: 'https://tracker-pm2.fortunejackpartners.com/link?btag=49422327_269479',
    },
  },
  {
    logo: arcanebet,
    name: 'Arcanebet',
    domain: 'arcanbet.com',
    feature: '100% Deposit Bonus',
    paymentMethods: [
      {
        imgSrc: visa,
        alt: 'visa',
      },
      {
        imgSrc: mastercard,
        alt: 'mastercard',
      },
      {
        imgSrc: interac,
        alt: 'interac',
      },
      {
        imgSrc: bitcoin,
        alt: 'bitcoin',
      },
    ],
    about: [
      'Arcanebet Casino is a relatively fresh entrant in the online gambling scene, but their concerted efforts have been directed towards appealing to a wide spectrum of players. Whether your preference leans towards video slots, classic casino table games, or engaging video poker titles like Double Jackpot Poker, arcanebet Casino has got you covered. At arcanebet Casino, the gaming library boasts an impressive arsenal of over 3000 slots, sourced from renowned providers such as NoLimit City, Big Time Gaming, and Relax. This Curacao-licensed casino has spared no expense when it comes to selecting top-tier developers, with an esteemed lineup of software providers that include some of the most distinguished names in the industry. ',
      " Customer support at arcanebet Casino is nothing short of dedicated and dependable. Their support team is at your service 24/7, prepared to respond to your queries and provide assistance whenever needed. Furthermore, for newcomers, there's a generous welcome bonus up for grabs as an added incentive.",
    ],
    quickFacts: {
      wager: '35x b',
      minDeposit: '10 EUR',
      maxDeposit: '5 EUR ',
      vpnAllowed: 'Yes (only for accessibility of games) ',
      bonus: '(Balance must be above €1.00 at all times)',
      raw: 'Yes',
      crypto: 'Yes',
      url: 'https://www.arcanebet.com/en/welcome?btag=17674326_237782',
    },
  },
  {
    logo: moi,
    name: 'Moicasino',
    domain: 'moicasino.com',
    feature: '200% Deposit Bonus',
    paymentMethods: [
      {
        imgSrc: visa,
        alt: 'visa',
      },
      {
        imgSrc: mastercard,
        alt: 'mastercard',
      },
      {
        imgSrc: sofort,
        alt: 'sofort',
      },
      {
        imgSrc: mifinity,
        alt: 'mifinity',
      },
    ],
    about: [
      'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deserunt error minus temporibus eveniet quia dolores quas veniam aperiam? Voluptates, quaerat. Lorem ipsum dolor sit amet consectetur adipisicing elit. A maiores, voluptatum temporibus necessitatibus perspiciatis cumque repellendus vel magni neque placeat. ',
      ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates corporis corrupti doloremque fugiat vero aliquid. Nihil temporibus rem a error.',
    ],
    quickFacts: {
      wager: '30x b',
      minDeposit: '20 EUR',
      maxDeposit: 'No max bet',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'Yes',
      crypto: 'No',
      url: 'https://record.grandeaffiliates.com/_A0ym1eKRVP3i1MaLwMl4CWNd7ZgqdRLk/2/?pg=0',
    },
  },
  {
    logo: rabona,
    name: 'Rabona',
    domain: 'rabona.com',
    feature: '100% Sport/Casino Deposit Bonus',
    paymentMethods: [
      {
        imgSrc: visa,
        alt: 'visa',
      },
      {
        imgSrc: mastercard,
        alt: 'mastercard',
      },
      {
        imgSrc: bank,
        alt: 'bank',
      },
      {
        imgSrc: bitcoin,
        alt: 'bitcoin',
      },
    ],
    about: [
      'Rabona Casino offers a vast and diverse selection of games to cater to the preferences of all types of players. This includes a wide range of slots, table games, live casino offerings, and even virtual sports.',
      `They’re a relatively new yet promising player in the online gambling industry. With its extensive game selection, attractive bonuses, mobile compatibility, and strong commitment to player security, it has quickly gained recognition among avid gamblers. Whether you're a seasoned player or new to online gambling, Rabona Casino offers an exciting and secure platform for a memorable gaming experience.`,
    ],
    quickFacts: {
      wager: 'Dependant on country check T&C on-site',
      minDeposit: '20 EUR',
      maxDeposit: '50 EUR',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'No',
      crypto: 'Yes',
      url: 'https://rbn.trak7s.com/redirect.aspx?pid=2100948&lpid=14473&bid=6273',
    },
  },
  {
    logo: nomini,
    name: 'Nomini',
    domain: 'nomini.com',
    feature: '100% Deposit Donus + 200 Free Spins',
    paymentMethods: [
      {
        imgSrc: visa,
        alt: 'visa',
      },
      {
        imgSrc: mastercard,
        alt: 'mastercard',
      },
      {
        imgSrc: bitcoin,
        alt: 'bitcoin',
      },
      {
        imgSrc: etheruem,
        alt: 'etheruem',
      },
    ],
    about: [
      `Nomini Casino is a refreshing and fun online casino that stands out in the crowded world of online gaming. With its vast game selection, innovative promotions, and user-friendly design, it provides an enjoyable and potentially profitable gaming experience for players of all levels. Whether you're a seasoned gambler or a newcomer, Nomini Casino is worth exploring for its unique and fruity take on online gambling. `,
      `The casino's commitment to player security and fairness, as evidenced by its gaming license and encryption technology, instil confidence in its reliability. Moreover, the flexible welcome bonus system and loyalty program demonstrate that Nomini Casino is dedicated to rewarding its players generously. `,
    ],
    quickFacts: {
      wager: 'Dependant on country check T&C on-site',
      minDeposit: '10 EUR',
      maxDeposit: '5 EUR',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'No',
      crypto: 'Yes',
      url: 'https://nmn.trak7s.com/redirect.aspx?pid=2100948&lpid=4142&bid=5038',
    },
  },
  {
    logo: slotamba,
    name: 'Slotamba',
    domain: 'slotamba.com',
    feature: 'Exclusive 200% Deposit bonus (Code:ROLL)',
    paymentMethods: [
      {
        imgSrc: visa,
        alt: 'visa',
      },
      {
        imgSrc: mastercard,
        alt: 'mastercard',
      },
      {
        imgSrc: interac,
        alt: 'interac',
      },
      {
        imgSrc: bitcoin,
        alt: 'bitcoin',
      },
    ],
    about: [
      'Slotamba is an online casino and gaming platform that has been in operation since 2021. It offers a wide array of casino games, including slots, table games, video poker, and live casino options, catering to players in various countries. ',
      ` The platform prides itself on a user-friendly interface and often provides engaging website designs to enhance the gaming experience.
      Similar to other reputable online casinos, Slotamba typically extends various promotions and bonuses to attract new players and reward existing ones, such as welcome bonuses and free spins. They prioritize player safety and security by operating under strict licensing and regulatory frameworks while offering robust customer support services.`,
    ],
    quickFacts: {
      wager: '40x d+b',
      minDeposit: '20 EUR',
      maxDeposit: '12 EUR (or 5% of the bonus, whichever is lower)',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'Yes',
      crypto: 'Yes',
      url: 'https://go.slotambapartners.com/visit/?bta=35345&nci=5363',
    },
  },
  {
    logo: winz,
    name: 'Winz.io',
    domain: 'winz.io',
    feature: 'Wheel of Fortune Spin (Zero Wagering)',
    paymentMethods: [
      {
        imgSrc: bitcoin,
        alt: 'Bitcoin',
      },
      {
        imgSrc: etheruem,
        alt: 'Etheruem',
      },
      {
        imgSrc: litecoin,
        alt: 'litecoin',
      },
      {
        imgSrc: visa,
        alt: 'visa',
      },
    ],
    about: [
      `Winz Casino is a leading online gambling destination that excels in providing an exceptional gaming selection, enticing promotions, and a user-friendly interface. The casino's commitment to player security and fairness, bolstered by its gaming license and encryption technology, instills trust in its reliability. Whether you're a seasoned player or a newcomer, Winz Casino offers a captivating and immersive gaming experience that elevates your online gambling journey to new heights. With thousands of games to explore and an array of bonuses to claim, Winz Casino is a prime choice for those seeking top-quality entertainment and potential winnings in the world of online gaming. `,
    ],
    quickFacts: {
      wager: 'Zero',
      minDeposit: '10 EUR',
      maxDeposit: 'No max bet',
      vpnAllowed: 'Yes',
      bonus: 'No',
      raw: 'Yes',
      crypto: 'Yes',
      url: 'https://winz.io/?http_referrer=https:%2F%2Fluckyroll.tv%2F&stag=151421_6509643d379d07ad319b445d&__layerref=https:%2F%2Fluckyroll.tv%2F',
    },
  },
  {
    logo: wazamba,
    name: 'Wazamba',
    domain: 'Wazamba.com',
    feature: '100% Deposit Bonus + 200 Free Spins',
    paymentMethods: [
      {
        imgSrc: mastercard,
        alt: 'mastercard',
      },
      {
        imgSrc: bank,
        alt: 'bank',
      },
      {
        imgSrc: bitcoin,
        alt: 'bitcoin',
      },
      {
        imgSrc: litecoin,
        alt: 'litecoin',
      },
    ],
    about: [
      "Wazamba is not just an online casino; it's an immersive adventure that combines the thrill of gambling with an engaging narrative set in a jungle world. With its vast game selection, live casino options, loyalty program, and a unique adventure theme, Wazamba offers a fresh and exciting approach to online gaming. Whether you're a casual player looking for some entertainment or a seasoned gambler seeking a new adventure, Wazamba provides an all-encompassing experience that is both entertaining and rewarding.",
      ' Dive into the jungle and explore the exciting world of Wazamba today.',
    ],
    quickFacts: {
      wager: '35x d+b',
      minDeposit: '10 EUR',
      maxDeposit: '5 EUR (When playing with bonus funds)',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'No',
      crypto: 'Yes',
      url: 'https://wzb.trak7s.com/redirect.aspx?pid=2100948&lpid=2044&bid=5103',
    },
  },
  {
    logo: heycasino,
    name: 'HeyCasino!',
    domain: 'heycasino.com',
    feature: '200% Deposit Bonus',
    paymentMethods: [
      {
        imgSrc: visa,
        alt: 'visa',
      },
      {
        imgSrc: mastercard,
        alt: 'mastercard',
      },
      {
        imgSrc: skrill,
        alt: 'skrill',
      },
      {
        imgSrc: paysafe,
        alt: 'paysafe',
      },
    ],
    about: [
      'In the ever-evolving landscape of online entertainment, HeyCasino has emerged as a prominent player in the world of digital gambling and gaming. This innovative platform offers a comprehensive and exciting array of casino games, combining the thrill of traditional brick-and-mortar casinos with the convenience and accessibility of the internet.',
      " HeyCasino has carved out a niche in the online gambling industry by offering a wide range of games, a user-friendly platform, robust security measures, and a commitment to responsible gaming. If you're seeking a thrilling and enjoyable online casino experience, HeyCasino may be your ideal destination.",
    ],
    quickFacts: {
      wager: '30x b',
      minDeposit: '20 EUR',
      maxDeposit: 'No max bet',
      vpnAllowed: 'No',
      bonus: 'No',
      raw: 'Yes',
      crypto: 'No',
      url: 'https://record.grandeaffiliates.com/_A0ym1eKRVP1hg6WO2I1rgWNd7ZgqdRLk/1/?pg=0',
    },
  },
];

const Selection = () => {
  return (
    <section className=' mt-16 flex items-center flex-col justify-center '>
      <div className='sm:w-[1170px] flex flex-col  gap-8 px-4'>
        <header className='flex flex-col items-center gap-4 p-4 mb-12'>
          <div
            className='border-2 p-2 flex justify-center items-center mb-6 rounded-full'
            style={{
              border: '3px solid rgba(69, 255, 121, .3)',
            }}
          >
            <img src={dice} alt='Dice' />
          </div>
          <h1
            style={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'ClashGrotesk-bold, sans-serif',
              fontSize: '45px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '50px',
              letterSpacing: '2.15px',
              textTransform: 'uppercase',
            }}
          >
            OUR CASINO SELECTION
          </h1>
          <h2
            className='px-16'
            style={{
              textAlign: 'center',
              color: '#45FF79',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '26.4px',
              letterSpacing: '1px',
            }}
          >
            Curated list of the best Free Spins & First Deposit Bonuses
          </h2>
        </header>

        <main className='flex flex-col gap-4 px-4'>
          {selectionData.map((selectionData, index) => {
            return <SelectionCard {...selectionData} key={index} />;
          })}
        </main>
      </div>
    </section>
  );
};

export default Selection;
