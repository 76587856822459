import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import twitter from "../assets/navbar/twitter.png";
import youtube from "../assets/navbar/youtube.png";
import discord from "../assets/navbar/discord.png";

const navigation = [
  { name: "Home", goto: "/" },
  { name: "Stream", goto: "/stream" },
];

const Navbar = () => {
  return (
    <header className=' flex  justify-center ' id='header'>
      <div className='flex justify-between items-center w-full px-6 lg:w-[1152px]'>
        <Link to='/'>
          {' '}
          <img src={logo} alt='logo' className='w-40 h-8 lg:w-80 lg:h-10' />
        </Link>
        {/* Desktop View  */}
        <div className='flex gap-x-[18px]'>
          <nav className='flex gap-4 lg:gap-12 text-white justify-center items-center '>
            {navigation.map(({ name, goto }, index) => {
              return (
                <NavLink to={goto} key={index} className={`text-lg`}>
                  {name}
                </NavLink>
              );
            })}
          </nav>

          <div className='hidden lg:flex gap-x-[18px] p-5 border-l border-[#3A3A3A]'>
            <a
              href='https://twitter.com/LuckyRollTV'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={twitter}
                alt='Icon'
                className='w-[28px] h-[28px] rounded-full'
              />
            </a>
            <a
              href='https://www.youtube.com/@LuckyRollTV'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={youtube}
                alt='Icon'
                className='w-[28px] h-[28px] rounded-full'
              />
            </a>
            <a
              href='https://discord.gg/nUaHnqGJVV'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={discord}
                alt='Icon'
                className='w-[28px] h-[28px] rounded-full'
              />
            </a>
          </div>
        </div>
      </div>
      {/* Mobile View  */}
      {/* To be Continued */}
    </header>
  );
};

export default Navbar;
