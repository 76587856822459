import React from "react";

const SiteName = ({ logo, domain }) => {
  return (
    <div className="flex px-10 lg:flex-col justify-between py-14 lg:w-[213px] bg-[#45FF79]">
      <div className="flex flex-col justify-center items-center gap-4 ">
        <div className="bg-[#212536] py-5 px-4 rounded-full">
          <img src={logo} alt="sample" className="w-26 h-8" />
        </div>
        <span
          className=""
          style={{
            color: "#212536",
            textAlign: "center",
            fontFamily: "ClashGrotesk-semibold, sans-serif",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "23.467px" /* 167.619% */,
            textTransform: "uppercase",
            letterSpacing: "1px",
          }}
        >
          {domain}
        </span>
      </div>
      <p
        className="px-8 text-end lg:text-center lg:px-6 lg:p-0 self-end justify-self-end "
        style={{
          color: "#212536",
          fontFamily: "ClashGrotesk-medium, sans-serif",
          fontSize: "13px",
          lineHeight: "15px" /* 167.619% */,
        }}
      >
        This offer is only valid for newly registered users.
      </p>
    </div>
  );
};

export default SiteName;
