import React from "react";

const CTA = () => {
  return (
    <div className='flex flex-col gap-8 px-4 lg:x-40 items-center'>
      <h1
        style={{
          color: '#FFF',
          textAlign: 'center',
          fontFamily: 'ClashGrotesk-bold, sans-serif',
          fontSize: '45px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '50px',
          letterSpacing: '2.15px',
          textTransform: 'uppercase',
        }}
      >
        JOIN THE <span className='text-[#45FF79]'>LUCKY</span> TRAIN
      </h1>
      <p
        className='text-[#CDD4EE] text-justify sm:text-center text-[17px] font-normal leading-[26.4px] opacity-[0.7] lg:px-[260px] mb-10 sm:w-[1170px] '
        style={{ fontFamily: 'ClashGrotesk-extralight, sans-serif' }}
      >
        Welcome to LuckyRollTV – the premier streaming channel for online casino
        entertainment. We are dedicated to providing our community with the very
        best in online casino gaming experience. We work hard to ensure that we
        only feature the best casinos to our audience.
      </p>
    </div>
  );
};

export default CTA;
