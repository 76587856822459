import React from "react";
import Modal from "./home/popup/Modal";
import { useToggle } from "@react-md/utils";

const InfoIcon = ({ position, logo, domain, about, quickFacts }) => {
  const [visible, enable, disable] = useToggle(false);
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        className={`${position} right-5 z-10 cursor-pointer fill-current text-white hover:text-[#cbf4d6]`}
        onClick={enable}
      >
        <path
          id="Vector"
          d="M9 15H11V9H9V15ZM10 0C4.475 0 0 4.475 0 10C0 15.525 4.475 20 10 20C15.525 20 20 15.525 20 10C20 4.475 15.525 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM9 7H11V5H9V7Z"
        />
      </svg>
      <Modal
        visible={visible}
        disable={disable}
        logo={logo}
        domain={domain}
        about={about}
        quickFacts={quickFacts}
      />
    </>
  );
};

export default InfoIcon;
