import React from "react";

const RenderTitle = () => {
  return (
    <h1
      className="max-w-4xl px-2 lg:px-14 "
      style={{
        color: "#FFF",
        textAlign: "center",
        fontFamily: "ClashGrotesk-bold, sans-serif",
        fontSize: "45px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "50px",
        letterSpacing: "2.15px",
        textTransform: "uppercase",
      }}
    >
      WHAT MAKES AN ONLINE CASINO THE RIGHT CHOICE?
    </h1>
  );
};

export default RenderTitle;
