import React from "react";
import CTA from "../components/stream/CTA";
import RenderImage from "../components/stream/RenderImage";
import About from "../components/stream/About";

const Stream = () => {
  return (
    <div className="py-16">
      <CTA />
      <RenderImage />
      <About />
    </div>
  );
};

export default Stream;
